import React from 'react';
import styles from './Error.module.scss';

const Error: React.FC = () => {
  return (
    <div className={styles.errorDiv}>
      <svg style={{ display: 'none' }}>
        <symbol id="icon-images" viewBox="0 0 32 32">
          <path
            fill="red"
            d="M15.004 0.085c-6.642 0.583-11.847 4.537-14.052 10.681-0.839 2.347-1.095 5.66-0.612 8.192 1.223 6.428 6.272 11.477 12.7 12.7 1.621 0.313 4.295 0.313 5.916 0 6.428-1.223 11.477-6.272 12.7-12.7 0.313-1.621 0.313-4.295 0-5.916-1.067-5.604-4.964-10.126-10.425-12.089-1.678-0.612-4.565-1.010-6.229-0.868zM13.227 12.302l2.773 2.773 2.773-2.773c1.522-1.522 2.859-2.773 2.972-2.773 0.284 0 0.725 0.427 0.725 0.697 0 0.128-1.252 1.479-2.773 3.001l-2.773 2.773 2.773 2.773c1.522 1.522 2.773 2.859 2.773 2.972 0 0.284-0.427 0.725-0.697 0.725-0.128 0-1.479-1.252-3.001-2.773l-2.773-2.773-2.773 2.773c-1.522 1.522-2.873 2.773-2.972 2.773-0.284 0-0.725-0.427-0.725-0.697 0-0.128 1.252-1.479 2.773-3.001l2.773-2.773-2.773-2.773c-1.522-1.522-2.773-2.859-2.773-2.972 0-0.284 0.427-0.725 0.697-0.725 0.128 0 1.479 1.252 3.001 2.773z"
          ></path>
        </symbol>
      </svg>

      <svg width="150" height="150">
        <use href="#icon-images" />
      </svg>
      <h1>Ой лишенько, щось пішло не так‼</h1>
      <p>Initial server error: 500</p>
    </div>
  );
};

export default Error;
