import axios from 'axios';

import { getToken } from '../services/local-storage-controller';
import { root as apiUrl } from './config';

axios.defaults.baseURL = apiUrl;

const instance = axios.create({
  baseURL: `${apiUrl}`,
});

instance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return error.data.message;
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      // Якщо немає відповіді від сервера (наприклад, немає інтернету або сервер недоступний)
      console.error('Network error:', error);
      window.location.href = '/error'; // Перенаправляємо на сторінку помилки
    } else if (error.response?.status === 500) {
      console.error('Internal server:', error);
      // Перенаправлення на сторінку помилки при статусі 500
      window.location.href = '/error'; // Використовуємо навігацію на сторінку помилки
    }
    return Promise.reject(error);
  }
);

export const temporaryToken = 'Bearer ' + getToken();

export default instance;
